@import url(https://fonts.googleapis.com/css?family=Roboto:regular,500,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:700);

@font-face {
  font-family: 'SF-Pro-Text';
  src: url('../../assets//fonts/SF-Pro-Text/SF-Pro-Text-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'SF-Pro-Text';
  src: url('../../assets//fonts/SF-Pro-Text/SF-Pro-Text-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SF-Pro-Text';
  src: url('../../assets//fonts/SF-Pro-Text/SF-Pro-Text-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'SF-Pro-Text';
  src: url('../../assets//fonts/SF-Pro-Text/SF-Pro-Text-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
